/*
 * @Author: fangjun
 * @Date: 2023-03-16 17:06:43
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-03-29 16:17:30
 * @FilePath: \src\utils\konvaEditor\generateID.js
 */
import { nanoid } from 'nanoid'

export default function generateID() {
  let id = nanoid(32)
  return id
}
